<template>
	<div style="width: 100%;min-height: 100vh;background-image: linear-gradient(white, #b5d6ff);">
		<div>
			<img style="width: 80%;margin-left: 11%;margin-top: 5%"
			     src="https://czsc.oss-cn-beijing.aliyuncs.com/bj/material_turnover.png"/>
		</div>
		<!--	搜索-->
		<div style="margin-top: 5%">
			<el-input
				v-model="material_name"
				placeholder="请输入查询材料"
				clearable
				@blur="getMaterialTurnover"
			/>
		</div>
		<!--	已有材料列表-->
		<div v-for="(item, i) in material_turnover_list" :key="i">
			<div id="material_turnover_div">
				<div>
					<img style="width: 90%;margin-top: 15%" :src="item.photo_url" @click="selectImg(item)"/>
				</div>
				<div @click="changeMaterial(item.bdst, item.id)">
					<div
						style="margin-top: 10%;font-size: 25px;display: grid;grid-template-columns: 1fr 1fr;font-weight: bolder;color: red">
						<div>
							{{ item.bdstName }}
						</div>
						<div style="margin-left: 18%">
							{{ item.materialName }}
						</div>
						<el-divider style="width: 200%"/>
					</div>

					<div
						style="margin-top: -5%;font-size: 18px;;display: grid;grid-template-columns: 1fr 1fr;;font-weight: bolder">
						<div>
							型号：{{ item.materialModel }}
						</div>
						<div style="margin-left: 8%;">
							数量：{{ item.materialNum + item.materialUnit }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--	弹出选择框-->
		<div>
			<el-dialog
				v-model="dialogVisible"
				title="提示"
				width="500"
			>
				<span>请选择操作</span>
				<template #footer>
					<div class="dialog-footer">
						<el-button type="danger" @click="reomveMaterial()">删除周转材料</el-button>
						<el-button type="primary" @click="changeMaterialDis"> 修改周转材料库存</el-button>
					</div>
				</template>
			</el-dialog>
		</div>

		<!--	修改周转材料库存-->
		<div>
			<el-dialog
				v-model="dialogVisibleChange"
				title="修改库存"
				width="500"
			>
				<el-input
					v-model="material_num"
					placeholder="请输入材料库存"
					clearable
				/>
				<template #footer>
					<div class="dialog-footer">
						<el-button type="danger" @click="dialogVisibleChange = false">取消</el-button>
						<el-button type="primary" @click="changMaterialNum"> 修改周转材料库存</el-button>
					</div>
				</template>
			</el-dialog>
		</div>

		<!--	周转材料放大照片-->
		<div>
			<el-dialog
				v-model="dialogVisibleImg"
				title="图片"
				:width="screenWidth * 1"
			>
				<img :src="amplify_img_url" :style="{ width: screenWidth * 0.9 + 'px', height: screeHeight * 0.9 + 'px' }"/>
				<img :src="amplify_img_url_reason" :style="{ width: screenWidth * 0.9 + 'px', height: screeHeight * 0.9 + 'px' }"/>
				<template #footer>
					<div class="dialog-footer">
						<el-button type="danger" @click="cancelImg">取消</el-button>
					</div>
				</template>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {Decrypt} from "@/utils/secret";

export default {
	name: "Select_material_turnover",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			material_turnover_list: [],
			dialogVisible: false,
			bdst: '',
			operate_bdst: '',
			operate_id: '',
			material_name: '',
			dialogVisibleChange: false,
			material_num: '',
			dialogVisibleImg: false,
			amplify_img_url: '',
			screenWidth: '',
			screeHeight: '',
			amplify_img_url_reason: ''
		}
	},
	created() {
		if (!this.$route.params.key) {
			this.$router.push("/error")
		} else {
			this.screenWidth  = document.documentElement.clientWidth
			this.screeHeight  = document.documentElement.clientHeight
			this.bdst = this.$route.params.bdst
			this.getMaterialTurnover()
		}
	},
	methods: {
		getMaterialTurnover() {
			this.axios.get('/materialTurnoverMain/getTurnoverList', (response) => {
				//console.log(response)
				this.material_turnover_list = response.obj
				for (let i = 0; i < this.material_turnover_list.length; i++) {
					this.material_turnover_list[i].photo_url = Decrypt(this.material_turnover_list[i].photo_url)
					this.material_turnover_list[i].photo_url_reason = Decrypt(this.material_turnover_list[i].photo_url_reason)
					//console.log(this.material_turnover_list[i].photo_url)
				}
			}, {
				material_name: this.material_name
			})
		},
		changeMaterial(bdst, id) {
			if (bdst === this.bdst) {
				this.dialogVisible = true
				this.operate_bdst = bdst
				this.operate_id = id
			}
		},
		reomveMaterial() {
			this.axios.post('/materialTurnoverMain/removeById', (response) => {
				if (response.obj) {
					ElMessage.success('删除周转材料成功!')
					this.dialogVisible = false
					this.getMaterialTurnover()
				}
			}, {
				id: this.operate_id
			})
		},
		changeMaterialDis() {
			this.dialogVisible = false
			this.dialogVisibleChange = true
		},
		changMaterialNum() {
			this.axios.post('/materialTurnoverMain/updataMaterialNum', (response) => {
				if (response.obj) {
					ElMessage.success('修改周转材料库存成功!')
					this.dialogVisibleChange = false
					this.material_num = ''
					this.getMaterialTurnover()
				}
			}, {
				id: this.operate_id,
				material_num: this.material_num
			})
		},
		selectImg(item){
			this.dialogVisibleImg = true
			this.amplify_img_url = item.photo_url
			this.amplify_img_url_reason = item.photo_url_reason
		},
		cancelImg(){
			this.dialogVisibleImg = false
			this.amplify_img_url = ''
			this.amplify_img_url_reason = ''
		}
	}
}
</script>

<style>
#material_turnover_div {
	width: 90%;
	border-radius: 10px;
	border: 1px solid #939393;
	display: grid;
	grid-template-columns: 30% 70%;
	height: 15vh;
	overflow: hidden;
	margin: 15% auto 0 auto;
}
</style>